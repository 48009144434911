import React, {useState} from 'react';
import UploadFile from './UploadFile';
import FileConfiguration from './FileConfiguration';

function HomePage() {
	const [fileObject, setFileObject] = useState<File | null>(null);

	const onFileUploaded = (file: File) => {
		console.log('Dropped file:', file);
		setFileObject(file);
	};

	return (
		<div className={'container-fluid text-center mt-5'}>
			<h1 className={'m-5'}>
				DICE 2.0
			</h1>
			{fileObject ?
				<FileConfiguration fileObject={fileObject}/> :
				<UploadFile handleUpload={onFileUploaded}/>
			}
		</div>
	);
}

export default HomePage;
