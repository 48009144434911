import React, {useEffect, useRef, useState} from 'react';
import './FileConfiguration.scss';
import {BsFiletypeXlsx} from 'react-icons/bs';
import {Col, Container, Form, Row} from 'react-bootstrap';
import CommonService from '../services/CommonService';

export enum SCHEMA_TYPE {
	CSV = 'CSV',
	XLS = 'XLS',
	XLSX = 'XLSX'
}

export enum DATA_TYPE {
	INT = 'int',
	STRING = 'varchar',
	DECIMAL = 'decimal'
}

interface FileConfigurationProps {
	fileObject: File | null;
}

export interface ColumnSchema {
	name: string;
	dataType: DATA_TYPE;
}

export interface SchemaDefinition {
	id: number,
	name: string;
	extensionType: SCHEMA_TYPE;
	columns: ColumnSchema[];
	updatedBy?: string;
}

function FileConfiguration({fileObject}: FileConfigurationProps) {
	const [selectedValue, setSelectedValue] = useState<SchemaDefinition>();
	const templateOptionsRef = useRef<SchemaDefinition[]>([]);

	useEffect(() => {
		async function fetchData() {
			try {
				templateOptionsRef.current = await CommonService.getTemplatesList();
				setSelectedValue(templateOptionsRef.current[0]);
			} catch(error) {
				console.error('Error fetching data:', error);
			}
		}

		fetchData().then(r => console.log(r));
	}, []);

	const handleSelectChange = (event: any) => {
		const templateId = Number(event.target.value);
		const foundElement = templateOptionsRef.current.find(item => item.id === templateId);
		setSelectedValue(foundElement);
	};

	return (
		<Container>
			<Row>
				<Col md={6} className={'text-start'}>
					<h4>1. Selected file:</h4>
					<h5 className={'pt-2'}>
						<BsFiletypeXlsx style={{fontSize: '2rem'}}/>
						<span className={'ps-2'} data-testid={'fileName'}>{fileObject ? fileObject.name : 'None'}</span>
					</h5>
				</Col>
				<Col md={6} className={'text-start'}>
					<h4>2. Choose template:</h4>
					{templateOptionsRef.current.length > 0 ?
						<Form.Select aria-label="Template selection" defaultValue="Select from list below"
									 onChange={handleSelectChange}>
							{templateOptionsRef.current.map((option) => (
								<option key={option.name} value={option.id}>
									{option.name}
								</option>
							))}
						</Form.Select> : ''}
					{selectedValue ?
						<ul>
							{selectedValue.columns.map((column) => (
								<li key={column.name} value={column.name}>
									{column.name}
								</li>
							))}
						</ul> : ''}
				</Col>
			</Row>
		</Container>
	);
}


export default FileConfiguration;
