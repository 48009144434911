import React, {useCallback, useState} from 'react';
import {Alert, Button, Container} from 'react-bootstrap';
import {FaTimes} from 'react-icons/fa';
import Papa from 'papaparse';
import './UploadFile.scss';
import * as XLSX from 'xlsx';
import {SCHEMA_TYPE} from './FileConfiguration';

type ResultObject = {
	name: string,
	details: string,
	messageType: 'danger' | 'warning' | 'success'
}

const UploadFile: React.FC<{ handleUpload: (file: File) => void }> = ({handleUpload}) => {
	const [isDragging, setIsDragging] = useState(false);
	const [isDragOver, setDragOver] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [result, setResult] = useState({} as ResultObject);

	const MAX_FILE_SIZE: number = 20000000; // 20MB
	const POSSIBLE_EXTENSIONS: string[] = Object.values(SCHEMA_TYPE);

	const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setDragOver(true);
	};

	const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setIsDragging(true);
		setDragOver(false);
	};

	const handleDropEvent =
		(event: React.DragEvent<HTMLDivElement>) => {
			event.preventDefault();
			setIsDragging(false);
			setDragOver(false);
			handleClose();
			processFile(event.dataTransfer.files[0]);
		};

	const convertFile = (file: File) => {
		const reader = new FileReader();
		reader.onload = (e) => {
			const workbook = XLSX.read(e.target?.result, {type: 'binary'});
			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];
			const csvData = XLSX.utils.sheet_to_csv(worksheet);

			Papa.parse(csvData, {
				beforeFirstChunk: (chunk) => {
					const headers = Papa.parse(chunk, {
						preview: 1,
						header: true,
					}).meta.fields;

					console.log('Headers: ', headers);
				},
				complete: (results: Papa.ParseResult<any>) => {
					console.log('Content: ', results.data);
					handleUpload(file);
				},
				error: (error: any) => {
					console.error(error);
				},
				header: true,
			});
		};
		reader.readAsBinaryString(file);
	};

	const isFileValid = (file?: File): Promise<ResultObject> => {
		return new Promise((resolve, reject) => {
			if(!file) return reject({
				name: 'Problem with File',
				details: 'An error occurred while uploading the file. Please check your file and try again.',
				messageType: 'danger'
			});
			const extension: string = file.name?.split('.').pop()?.toUpperCase() ?? '';
			if(file.size > MAX_FILE_SIZE) {
				reject({
					name: 'File size',
					details: 'The file you are trying to upload is exceeding the maximum size',
					messageType: 'danger'
				});
			}
			if(!POSSIBLE_EXTENSIONS.includes(extension)) {
				reject({
					name: 'File Extension',
					details: 'Uploading file should have <b>csv</b>, <b>xlsx</b> or <b>xls</b> extension.',
					messageType: 'danger'
				});
			}
			resolve({
				name: 'File upload successful',
				details: 'Your file has been uploaded',
				messageType: 'success'
			});
		});
	};

	const processFile = useCallback((file: File) => {
		isFileValid(file).then((res: ResultObject) => {
			setResult(res);
			convertFile(file);
		}).catch((error: ResultObject) => {
			setResult(error);
		}).finally(() => {
			setShowAlert(true);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if(files && files.length) {
			const file = files[0];
			processFile(file);
		}
	};


	const handleClose = () => {
		setShowAlert(false);
	};

	return (
		<div className={'upload-file-component'}>
			<Container
				className={isDragging ? 'drop-area d-flex align-items-center justify-content-center is-dragging' : 'drop-area d-flex align-items-center justify-content-center'}
				data-testid={'drop-area'}
				onDragOver={handleDragOver}
				onDrop={handleDropEvent}
				onDragLeave={handleDragLeave}
			>
				<h3>{isDragOver ? 'Drop file HERE' : 'Drag and Drop a file here'}</h3>
			</Container>
			{showAlert ? (
				<Alert variant={`${result.messageType}`} className="position-fixed top-0 end-0 m-2 alert-static-width">
					<div>
						<b>{result.name}</b>
						<Button variant="" onClick={handleClose} className={'pull-right'}>
							<FaTimes/>
						</Button>
						<p dangerouslySetInnerHTML={{__html: result.details}}></p>
					</div>
				</Alert>
			) : null}
			<Container className={'mt-3'}>
				<label className="btn btn-primary">
					Upload File
					<input type="file" onChange={handleFileUpload} style={{display: 'none'}}/>
				</label>
			</Container>
		</div>

	);
};
export default UploadFile;
