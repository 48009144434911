import axios, {AxiosError} from 'axios';
import {SchemaDefinition} from '../components/FileConfiguration';

const API_URL: string = process.env.REACT_APP_API_URL ?? 'http://localhost:3000/';

class CommonService {
	async checkConnectivity(): Promise<string> {
		return axios.get(API_URL)
			.then(response => response.data)
			.catch(error => {
				this.getErrorMessage(error);
			});
	}

	async getTemplatesList(): Promise<SchemaDefinition[]> {
		return axios.get(API_URL + 'schema-definitions/')
			.then(response => {
				return response.data.map((item: any) => ({
					id: item.schema_id,
					name: item.schema_name,
					extensionType: item.schema_type,
					columns: item.columns.map((item: any) => ({
						name: item.column_name,
						dataType: item.column_type
					})),
					updatedBy: item.schema_type
				}));
			})
			.catch(error => {
				this.getErrorMessage(error);
			});
	}

	private getErrorMessage(error: AxiosError): void {
		switch(error.code) {
		case 'ERR_CONNECTION_REFUSED':
		case 'ERR_NETWORK': {
			throw new AxiosError('Connection refused. The server might not be running.');
		}
		default:
			console.error(error.code);
			throw new AxiosError(error.message);
		}
	}
}

const commonService: CommonService = new CommonService();
export default commonService;
