import './App.scss';
import React, {useEffect, useState} from 'react';
import {Route, Routes} from 'react-router-dom';
import HomePage from './components/HomePage';
import NotFound from './components/NotFound';
import CommonService from './services/CommonService';

function App() {
	const [backendData, setBackendData] = useState('Loading!');

	useEffect(() => {
		CommonService.checkConnectivity()
			.then((data: string) => {
				setBackendData(data);
			}).catch((err: Error) => {
			setBackendData(err.message);
		});
	}, []);

	return (
		<>
			<p data-testid={'backendData'} className={'position-absolute text-end'} style={{right: 0}}> Server
				status: <br/>{backendData}</p>
			<Routes>
				<Route path="/" element={<HomePage/>}/>
				<Route path="*" element={<NotFound/>}/>
			</Routes>
		</>
	);
}


export default App;
